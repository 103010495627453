type Props = {
  rating: number;
};

export default function RateIcon({ rating }: Props) {
  const iconElements = [
    '🙁',
    '😔',
    '😒',
    '🤨',
    '😐',
    '😏',
    '🙂',
    '🤪',
    '😆',
    '😍',
  ];
  let iconElement: any = <span role="img">{iconElements[rating - 1]}</span>;
  return <>{iconElement}</>;
}
