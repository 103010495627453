import { useEffect } from 'react';
import useCurrentUser from '../../services/useUser';

const Logout = () => {
  const { actions } = useCurrentUser();
  useEffect(() => {
    actions.logout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>Logging out...</div>;
};

export default Logout;
