import { useEffect } from 'react';
import { useParams } from 'react-router';
import Honk from '../../components/Honk';
import useHonk from '../../services/useHonk';

const Honks = () => {
  const { id } = useParams<{ id: string }>();
  const { actions, honk } = useHonk();
  useEffect(() => {
    if (!id) return;
    actions.getHonk(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <Honk honk={honk} onRate={(rating) => actions.rateHonk(id, rating)} />;
};

export default Honks;
