import Box from '@mui/material/Box';
import IComment from '../../interfaces/Comment';
import Avatar from '@mui/material/Avatar';
import { Card, CardContent, CardHeader } from '@mui/material';

type Props = {
  comment: IComment;
};

export default function Comment({ comment }: Props) {
  if (!comment) return null;
  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Card sx={{ my: 2 }}>
        <CardHeader
          title={`@${comment.user.handle} `}
          subheader={comment.createdAt}
          avatar={<Avatar src={comment.user.avatar} />}
        />
        <CardContent>{comment.body}</CardContent>
      </Card>
    </Box>  
  );
}
