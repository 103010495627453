import { signal } from '@preact/signals-core';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { transformUser } from '../transforms/user'
import LoginFormData from '../interfaces/LoginFormData';
import User from '../interfaces/User';
import { useSignal } from '../utils/react-signals-lite';
import api from './api';

const currentUserData = signal<{
  initialized: boolean;
  error: { message: string } | null;
  loading: boolean;
  currentUser: User | null;
}>({ initialized: false, error: null, loading: true, currentUser: null });

const onError = (error: AxiosError<{ message?: string } | undefined>) => {
  currentUserData.value = {
    ...currentUserData.value,
    loading: false,
    error: {
      message: error.response?.data?.message || 'Whoops! Something went wrong.',
    },
  };
};

export const useInitUser = () => {
  useSignal(currentUserData);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUserData.value.currentUser) return;

    const isLoginPath = /sign-up|login/.test(window.location.pathname);

    currentUserData.value = { ...currentUserData.value, loading: true };

    api
      .get('/current_user')
      .then(({ data: { user } }) => {
        if (!user) throw new Error('401'); // hack for now, need to prevent devise from redirecting and pass 401
        currentUserData.value = {
          initialized: true,
          error: null,
          loading: false,
          currentUser: transformUser(user),
        };
        if (isLoginPath) navigate('/');
      })
      .catch(() => {
        currentUserData.value = {
          ...currentUserData.value,
          initialized: true,
          loading: false,
        };
      });
  }, [navigate]);
};

const useCurrentUser = () => {
  useSignal(currentUserData);
  const navigate = useNavigate();
  const actions = {
    updateAvatar(fileInput: HTMLInputElement) {
      if (!fileInput.files) return;
      const formData = new FormData();
      formData.append('avatar', fileInput.files[0]);
      api
        .post('/users/avatar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(({ data: user }: { data: any }) => {
          currentUserData.value = {
            ...currentUserData.value,
            currentUser: transformUser(user),
          };
        })
        .catch(onError);
    },
    login(formData: LoginFormData) {
      currentUserData.value = { ...currentUserData.value, loading: true };
      api
        .post('/users/sign_in', { user: formData })
        .then(({ data: { user } }: { data: any }) => {
          if (!user) throw new Error('401');
          currentUserData.value = {
            ...currentUserData.value,
            currentUser: transformUser(user),
            loading: false,
            error: null,
          };
          navigate('/');
        })
        .catch(onError);
    },
    logout() {
      api
        .delete('/users/sign_out')
        .then(() => {
          currentUserData.value = {
            ...currentUserData.value,
            loading: false,
            error: null,
            currentUser: null,
          };
          navigate('/login');
        })
        .catch(() => {
          currentUserData.value = {
            ...currentUserData.value,
            loading: false,
            error: null,
            currentUser: null,
          };
          navigate('/login');
        });
    },
    signUp(formData: any) {
      currentUserData.value = { ...currentUserData.value, loading: true };
      api
        .post('/users', { user: formData })
        .then(({ data: user }: { data: any }) => {
          currentUserData.value = {
            ...currentUserData.value,
            currentUser: transformUser(user),
            loading: false,
            error: null,
          };
          navigate('/');
        })
        .catch(onError);
    },
    updateUser(formData: any) {
      // api.put
    },
  };
  return { actions, ...currentUserData.value };
};

export default useCurrentUser;
