import {
  AddAPhoto as AddAPhotoIcon,
  CalendarMonth as CalendarMonthIcon,
  Email as EmailIcon,
  Link as LinkIcon,
  Notes as NotesIcon,
} from '@mui/icons-material';
import { Avatar, Box, Button, IconButton, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useRef } from 'react';
import User from '../../interfaces/User';
import useCurrentUser from '../../services/useUser';

const DisplayGoose = ({ goose, onFollow, onUnfollow }: { goose: User, onFollow: any, onUnfollow: any }) => {
  const { currentUser, actions } = useCurrentUser();
  const inputRef = useRef<HTMLInputElement>(null);
  const isSelf = currentUser?.handle === goose.handle;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ position: 'relative', alignSelf: 'start' }}>
        <Avatar src={goose?.avatar} sx={{ width: 125, height: 125 }} />
        {isSelf && (
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              background: 'rgb(0,0,0,0.5)',
              '&:hover': {
                background: 'rgb(0,0,0,0.6)',
              },
            }}
            color="primary"
            onClick={() => inputRef.current?.click()}
          >
            <AddAPhotoIcon />
          </IconButton>
        )}
      </Box>
      {goose?.fullName && (
        <Typography variant="h4">
          {goose.fullName} {goose?.pronouns ? `(${goose.pronouns})` : ''}
        </Typography>
      )}
      <Typography variant="h5" component="div">
        @{goose?.handle}
      </Typography>
      <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <EmailIcon />
        <span>{goose?.email}</span>
      </Typography>
      <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <>
          <CalendarMonthIcon /> Joined{' '}
          {dayjs(goose?.createdAt).format('MMMM YYYY')}
        </>
      </Typography>
      <Typography component={Link} href={`/goose/${goose?.handle}/friends`} color="inherit" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span>
          <b>{goose?.followingCount}</b> Following
        </span>
        <span>
          <b>{goose?.followerCount}</b> Followers
        </span>
      </Typography>
      <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <NotesIcon sx={{ alignSelf: 'start' }} />
        Bio
      </Typography>
      <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <LinkIcon />
        <a href={'https://google.com'}>Link</a>
      </Typography>
      {!isSelf && !goose?.currentUserFollowing && (
        <Button variant="contained" onClick={onFollow}>Follow</Button>
      )}
      {!isSelf && goose?.currentUserFollowing && (
          <Button variant="contained" onClick={onUnfollow}>Unfollow</Button>
      )}
      {isSelf && (
        <>
          <Box sx={{ visibility: 'hidden', height: 0 }}>
            <input
              ref={inputRef}
              type="file"
              onChange={(e) => actions.updateAvatar(e.target)}
            />
          </Box>
          <Button>Edit Profile</Button>
        </>
      )}
    </Box>
  );
};

export default DisplayGoose;
