import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import useCurrentUser from '../../services/useUser';

const SignUp = () => {
  const { actions, error, loading } = useCurrentUser();

  return (
    <Card
      sx={{
        justifySelf: 'center',
        m: '0 auto',
        maxWidth: 480,
        width: '100%',
      }}
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = Object.fromEntries(new FormData(e.currentTarget));
        actions.signUp(formData);
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {error && <Alert severity="error">{error.message}</Alert>}
        <TextField
          name="email"
          type="email"
          label="Email"
          required
          disabled={loading}
        />
        <TextField name="handle" label="Username" required disabled={loading} />
        <TextField name="firstName" label="First Name" disabled={loading} />
        <TextField name="lastName" label="Last Name" disabled={loading} />
        <TextField name="pronouns" label="Pronouns" disabled={loading} />
        <TextField
          name="phone"
          type="tel"
          label="Phone"
          required
          disabled={loading}
        />
        <TextField
          name="password"
          type="password"
          label="Password"
          required
          disabled={loading}
        />
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={loading}
        >
          Sign Up
        </LoadingButton>
        <Button component={Link} to="/login" disabled={loading}>
          Already have an account? Login
        </Button>
      </CardActions>
    </Card>
  );
};

export default SignUp;
