import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { LoadingButton } from '@mui/lab';
import { Slide, SlideProps, Snackbar } from '@mui/material';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const useServiceWorker = () => {
  const [loading, setLoading] = useState(false);
  const [serviceWorker, setServiceWorker] = useState<ServiceWorker | null>(
    null,
  );

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate(registration) {
        setServiceWorker(registration.waiting);
      },
    });
  }, []);

  return {
    actions: {
      close() {
        setServiceWorker(null);
      },
      update() {
        setLoading(true);
        if (!serviceWorker) return;
        // Add listener for state change of service worker
        serviceWorker.onstatechange = () => {
          if (
            serviceWorker?.state === 'activated' &&
            navigator.serviceWorker.controller
          ) {
            // Reload page if waiting was successfully skipped
            window.location.reload();
          }
        };
        serviceWorker.postMessage({ type: 'SKIP_WAITING' });
        setServiceWorker(null);
      },
    },
    loading,
    serviceWorker,
  };
};

const SlideTransition = (props: SlideProps) => (
  <Slide {...props} direction="up" />
);

const ServiceWorker = () => {
  let container = document.querySelector('#sw-snackbar');
  if (!container) {
    container = document.createElement('div');
    container.id = 'sw-snackbar';
    document.body.appendChild(container);
  }
  const { actions, loading, serviceWorker } = useServiceWorker();
  return createPortal(
    <Snackbar
      open={Boolean(serviceWorker)}
      onClose={actions.close}
      message="A new update is available!"
      action={
        <LoadingButton
          sx={{
            '& .MuiLoadingButton-loadingIndicator': {
              color: 'primary.main',
            },
          }}
          variant="outlined"
          loading={loading}
          onClick={actions.update}
        >
          Update Now
        </LoadingButton>
      }
      TransitionComponent={SlideTransition}
    />,
    container,
  );
};

export default ServiceWorker;
