import { signal } from '@preact/signals-core';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import DisplayGoose from '../../components/DisplayGoose';
import api from '../../services/api';
import { useSignal } from '../../utils/react-signals-lite';
import { transformUser } from '../../transforms/user';
import { AxiosError } from 'axios';
import { Box, Paper } from '@mui/material';
import IHonk from '../../interfaces/Honk';
import Honk from '../../components/Honk';

const gooseData = signal<{
  data: any;
  honks: IHonk[];
  error: any;
  loading: boolean;
}>({
  data: null,
  honks: [],
  error: null,
  loading: false,
});

const onError = (error: AxiosError<{ message?: string } | undefined>) => {
  gooseData.value = {
    ...gooseData.value,
    loading: false,
    error: {
      message: error.response?.data?.message || 'Whoops! Something went wrong.',
    },
  };
};

const useGoose = (handle?: string) => {
  useSignal(gooseData);
  useEffect(() => {
    if (!handle) return;
    gooseData.value = { ...gooseData.value, loading: true };
    api
      .get(`/users/${handle}`)
      .then(
        ({ data }) =>
          (gooseData.value = {
            ...gooseData.value,
            data: data[0],
            honks: [],
            error: null,
            loading: false,
          }),
      )
      .catch(
        (err) =>
          (gooseData.value = {
            ...gooseData.value,
            error: err.response.data,
            loading: false,
          }),
      );
  }, [handle]);

  const actions = {
    followUser(userHandle: String) {
      api
        .post('/follows', { followee: userHandle })
        .then(({ data: user }: { data: any }) => {
          gooseData.value = {
            ...gooseData.value,
            data: transformUser(user),
          };
        })
        .catch(onError);
    },
    unFollowUser(userHandle: String) {
      api
        .put('/follows/' + userHandle, { followee: userHandle })
        .then(({ data: user }: { data: any }) => {
          gooseData.value = {
            ...gooseData.value,
            data: transformUser(user),
          };
        })
        .catch(onError);
    },
    getGooseHonks(userHandle: any) {
      api
        .get('/' + userHandle + '/honks')
        .then(
          ({ data }) =>
            (gooseData.value = {
              ...gooseData.value,
              honks: data,
            }),
        )
        .catch(onError);
    },
    rateHonk(id: string | undefined, rating: number) {
      api.post<IHonk>(`/honks/${id}/vote`, { rating }).then(({ data }) => {
        gooseData.value = {
          ...gooseData.value,
          honks: gooseData.value.honks.map((h) =>
            h.uuid === data.uuid ? data : h,
          ),
        };
      });
    },
  };

  return { ...gooseData.value, actions };
};

const Goose = () => {
  const { handle } = useParams<{ handle: string }>();
  const { data, honks, actions } = useGoose(handle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => actions.getGooseHonks(handle), [handle]);

  if (!data) return null;

  return (
    <>
      <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
        <DisplayGoose
          goose={data}
          onFollow={() => actions.followUser(data?.handle)}
          onUnfollow={() => actions.unFollowUser(data?.handle)}
        />
      </Paper>
      {honks && (
        <Box>
          {honks.map((honk: any) => (
            <Honk
              key={honk.uuid}
              honk={honk}
              onRate={(rating) => actions.rateHonk(honk.uuid, rating)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default Goose;
