import { Box, CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Nav from './components/Nav';
import ProtectedRoute from './components/ProtectedRoute';
import Flock from './routes/Flock';
import Goose from './routes/Goose';
import Friends from './routes/Friends';
import Honks from './routes/Honks';
import Login from './routes/Login';
import Logout from './routes/Logout';
import Notifications from './routes/Notifications';
import Profile from './routes/Profile';
import SignUp from './routes/SignUp';
import { useInitUser } from './services/useUser';
import ServiceWorker from './components/ServiceWorker';

function App() {
  useInitUser();
  return (
    <>
      <CssBaseline />
      <Nav />
      <Box
        p={{ xs: 1, sm: 2 }}
        mt={10}
        ml={{ md: '250px' }}
        display="flex"
        justifyContent="center"
        sx={{
          transition: 'all 200ms',
        }}
        component="main"
      >
        <Box mx="auto" maxWidth={640} width="100%">
          <Routes>
            <Route path="/" element={<Flock />} />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/honks/:id"
              element={
                <ProtectedRoute>
                  <Honks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/goose/:handle"
              element={
                <ProtectedRoute>
                  <Goose />
                </ProtectedRoute>
              }
            />
            <Route
              path="/goose/:handle/friends"
              element={
                <ProtectedRoute>
                  <Friends />
                </ProtectedRoute>
              }
            />

            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="*"
              element={
                <div>
                  <h1>404 Not Found!</h1>
                </div>
              }
            />
          </Routes>
        </Box>
      </Box>
      <ServiceWorker />
    </>
  );
}

export default App;
