import { Badge, IconButton } from '@mui/material';
import User from '../../interfaces/User';
import { useNavigate } from 'react-router';
import { Notifications as NotificationsIcon } from '@mui/icons-material';

const UserMenu = ({ user }: { user: User }) => {
  const navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate('/notifications')}>
      <Badge badgeContent={user.notificationsCount} color="secondary">
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
};

export default UserMenu;
