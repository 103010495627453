import { signal } from '@preact/signals-core';
import { AxiosError } from 'axios';
import Notification from '../interfaces/Notification';
import { useSignal } from '../utils/react-signals-lite';
import api from './api';

const notificationData = signal<{
  notifications: Notification[];
  loading: boolean;
  error: boolean | { status?: number; message?: string };
}>({ notifications: [], loading: false, error: false });

const useNotifications = () => {
  useSignal(notificationData);
  const actions = {
    markRead(id: string) {
      api
      .put(`/notifications/${id}`)
      .then((res) => {
        notificationData.value = {
          ...notificationData.value,
          notifications: notificationData.value.notifications.filter((notification) => notification.id !== id),
          loading: false,
          error: false,
        };
      })
      .catch((err: AxiosError<{ message: string }>) => {
        const status = err.response?.status;
        const message = err.response?.data.message;
        notificationData.value = {
          ...notificationData.value,
          loading: false,
          error: { status, message },
        };
      });
    },
    getNotifications(unread: boolean = true) {
      notificationData.value = { ...notificationData.value, loading: true };
      api
        .get('/notifications', { params: { unread } })
        .then((res) => {
          notificationData.value = {
            notifications: res.data,
            loading: false,
            error: false,
          };
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const status = err.response?.status;
          const message = err.response?.data.message;
          notificationData.value = {
            ...notificationData.value,
            loading: false,
            error: { status, message },
          };
        });
    },
  };
  const { notifications, loading, error } = notificationData.value;
  return { actions, notifications, loading, error };
};

export default useNotifications;
