import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { IconButton, Link } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import INotification from '../../interfaces/Notification';

type Props = {
  notification: INotification;
  markRead: (id: string) => void;
};

export default function Notification({ notification, markRead }: Props) {  
  return (
    <ListItem
      alignItems="flex-start"
      component={Link}
      href={notification.url}
      color="inherit"
      secondaryAction={
        <IconButton
          onClick={() => markRead(notification.id)}
          edge="end"
          aria-label="mark as read"
        >
          <DoneIcon />
        </IconButton>
      }
    >
      <ListItemText primary={notification.message} />
      {/* <ListItemText primary={notification.readAt} /> */}
    </ListItem>
  );
}
