/**
 * A limited, lite version of @preact/signals-react that doesn't inject behavior into React itself.
 * @see https://gist.github.com/developit/f957536487a84dee334cfeb53748f865
 */
import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { computed, effect, signal, Signal } from "@preact/signals-core";

/** Wrap the given value in a Signal if it isn't already one, and make changes trigger a re-render. */
export function useSignal<T = any>(signalOrValue: T) {
  const sig = useConstant(() =>
    signalOrValue instanceof Signal ? signalOrValue : signal(signalOrValue)
  ) as T extends Signal ? T : Signal<T>;
  let val = sig.peek();
  const update = useState(val)[1];
  useLayoutEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    () => effect(() => val !== (val = sig.value) && update(val)),
    []
  );
  return sig;
}

/** Create a Signal derived from the return value of a function that accesses other Signals. */
export function useComputed<T = any>(compute: () => T) {
  return useSignal(useConstant(() => computed(compute)));
}

/** Run a function that accesses Signals, and re-run it if they change. Return a function to perform cleanup. */
export function useSignalEffect(compute: () => unknown, deps: any[] = []) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => effect(compute), deps);
}

// helper: useMemo(initializer, []) but stable in R18
function useConstant<T = any>(initializer: () => T) {
  const ref = useRef<T>();
  if (!ref.current) ref.current = initializer();
  return ref.current;
}
