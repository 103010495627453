import { signal } from '@preact/signals-core';
import { AxiosError } from 'axios';
import Honk from '../interfaces/Honk';
import { useSignal } from '../utils/react-signals-lite';
import api from './api';

const honkData = signal<{
  honks: Honk[];
  loading: boolean;
  error: boolean | { status?: number; message?: string };
}>({ honks: [], loading: false, error: false });

const useHonks = () => {
  useSignal(honkData);
  const actions = {
    createHonk(honk: FormData) {
      honkData.value = { ...honkData.value, loading: true };
      return api
        .post('/honks', honk, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(({ data: honk }) => {
          honkData.value = {
            ...honkData.value,
            honks: [honk, ...honkData.value.honks],
            loading: false,
            error: false,
          };
        })
        .catch((err: AxiosError) => {
          honkData.value = {
            ...honkData.value,
            loading: false,
            error: { status: err.status, message: err.message },
          };
        });
    },
    deleteHonk(id: string) {
      honkData.value = { ...honkData.value, loading: true };
      api
        .delete(`/honks/${id}`)
        .then(() => {
          honkData.value = {
            ...honkData.value,
            honks: honkData.value.honks.filter((honk) => honk.uuid !== id),
            loading: false,
            error: false,
          };
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const status = err.response?.status;
          const message = err.response?.data.message;
          honkData.value = {
            ...honkData.value,
            loading: false,
            error: { status, message },
          };
        });
    },
    getHonks(id?: string) {
      honkData.value = { ...honkData.value, loading: true };
      const endpoint = id ? `/${id}/honks` : '/honks';
      api
        .get(endpoint)
        .then((res) => {
          honkData.value = {
            honks: res.data,
            loading: false,
            error: false,
          };
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const status = err.response?.status;
          const message = err.response?.data.message;
          honkData.value = {
            ...honkData.value,
            loading: false,
            error: { status, message },
          };
        });
    },
    rateHonk(id: string | undefined, rating: number) {
      api.post<Honk>(`/honks/${id}/vote`, { rating }).then(({ data }) => {
        honkData.value = {
          ...honkData.value,
          honks: honkData.value.honks.map((h) =>
            h.uuid === data.uuid ? data : h,
          ),
        };
      });
    },
  };
  const { honks, loading, error } = honkData.value;
  return { actions, honks, loading, error };
};

export default useHonks;
