import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logout as LogoutIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import useCurrentUser from '../../services/useUser';
import UserMenu from './UserMenu';
import { LoadingButton } from '@mui/lab';
import EggHunter from './EggHunter';

const MENU_WIDTH = 250;

const MenuButton = ({
  children,
  text,
  to,
}: {
  children: React.ReactNode;
  text: string;
  to: string;
}) => {
  const location = useLocation();
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={to}
        selected={to === location.pathname}
      >
        <ListItemIcon>{children}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

export default function Nav() {
  // @ts-expect-error theme
  const isMedium = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const { loading, currentUser } = useCurrentUser();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${MENU_WIDTH}px)` },
          ml: { md: `${MENU_WIDTH}px` },
          transition: 'all 200ms',
        }}
      >
        <Toolbar sx={{ gap: 2, minHeight: { sm: '56px' } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { md: 'none' } }}
            onClick={() => setOpen(!open)}
          >
            <MenuIcon />
          </IconButton>

          <EggHunter />

          {currentUser ? (
            <UserMenu user={currentUser} />
          ) : (
            <LoadingButton
              color="inherit"
              component={Link}
              to="/login"
              loading={loading}
            >
              Login
            </LoadingButton>
          )}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={open}
        variant={isMedium ? 'permanent' : 'temporary'}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        ModalProps={{ keepMounted: true }}
      >
        <Box
          sx={{
            width: MENU_WIDTH,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'auto',
            height: '100%',
          }}
          role="presentation"
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}
        >
          <div>
            <MenuButton text="goosenow" to="/">
              <Avatar src="/logo.png" />
            </MenuButton>
          </div>
          {currentUser && (
            <div>
              <MenuButton text="Profile" to="/profile">
                <Avatar src={currentUser?.avatar} />
              </MenuButton>
              <MenuButton text="Logout" to="/logout">
                <LogoutIcon />
              </MenuButton>
            </div>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
}
