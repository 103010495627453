import { useCallback } from 'react';

const isMediaPlaying = () =>
  Array.from(document.querySelectorAll<HTMLMediaElement>('audio,video')).some(
    (el) => !el.paused,
  );

export default function useAutoPlay() {
  const ref = useCallback((node: HTMLMediaElement | null) => {
    if (node) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            if (isMediaPlaying()) return;
            node.muted = navigator.userActivation
              ?
                !navigator.userActivation.isActive
              : true;
            node.play();
          } else {
            node.pause();
          }
        },
        { threshold: 0.45 },
      );

      observer.observe(node);
    }
  }, []);

  return ref;
}
