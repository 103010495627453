import { List } from '@mui/material';
import { useEffect } from 'react';
import Notification from '../../components/Notification';
import INotification from '../../interfaces/Notification';
import useNotifications from '../../services/useNotifications';

const Notifications = () => {
  const { actions, notifications } = useNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => actions.getNotifications(), []);

  return (
    <List>
      {notifications.map((notification: INotification) => (
        <Notification
          key={notification.id}
          notification={notification}
          markRead={actions.markRead}
        />
      ))}
    </List>
  );
};

export default Notifications;
