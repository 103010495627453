import DisplayGoose from '../../components/DisplayGoose';
import useCurrentUser from '../../services/useUser';

const Profile = () => {
  const { currentUser } = useCurrentUser();
  if (!currentUser) return null;
  return <DisplayGoose goose={currentUser} onFollow={null} onUnfollow={null} />;
};

export default Profile;
