export default interface Honk {
  uuid: string;
  body: string;
  createdAt: string;
  honkedBy: string;
  rating_count: number;
  rating: number;
  currentUserRating?: number;
  commentCount: number;
}

export const isHonk = (obj: any): obj is Honk => Boolean(obj.body);
