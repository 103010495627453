import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import useCurrentUser from '../services/useUser';

const ProtectedRoute = ({ children }: { children: ReactElement | null }) => {
  const { initialized, currentUser } = useCurrentUser();

  if (!initialized) return <>Loading...</>;
  if (!currentUser) return <Navigate to="/login" />;
  return children;
};

export default ProtectedRoute;
