import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { Avatar, CardMedia, IconButton, Link, Slider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import CommentIcon from '@mui/icons-material/Comment';
import useCurrentUser from '../../services/useUser';
import RateIcon from './rateIcon';
import CommentForm from '../Comment/CommentForm';
import { useState } from 'react';
import CommentList from '../Comment/CommentList';
import useAutoPlay from '../../services/useAutoPlay';

interface HonkProps {
  honk: any;
  onDelete?(): void;
  onRate(rating: number): void;
}

export default function Honk({ honk, onDelete, onRate }: HonkProps) {
  const { currentUser } = useCurrentUser();
  const [showCommentForm, setShowCommentForm] = useState(false);
  const mediaRef = useAutoPlay();

  if (!honk) return null;

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Card sx={{ my: 2 }}>
        <CardHeader
          title={
            <Link
              href={`/goose/${honk.user.handle}`}
              underline="hover"
              color="inherit"
            >
              {honk.user.firstName} {honk.user.lastName} - {honk.user.pronouns}{' '}
              - @{honk.user.handle}
            </Link>
          }
          subheader={`${honk.createdAt}`}
          avatar={
            <Avatar
              component={Link}
              src={honk.user.avatar}
              href={`/goose/${honk.user.handle}`}
            />
          }
        />
        {honk.image && (
          <Box
            component="img"
            sx={{ width: '100%' }}
            src={honk.image}
            title={honk.body}
            alt="User uploaded content"
          />
        )}
        {honk.video && honk.video.endsWith('processing...') && (
          <CardContent>video {honk.video}</CardContent>
        )}
        {honk.video && !honk.video.endsWith('processing...') && (
          <CardMedia
            component="video"
            src={honk.video}
            ref={mediaRef}
            title={honk.body}
            controls
            playsInline
          />
        )}
        {honk.audio && honk.audio.endsWith('processing...') && (
          <CardContent>audio {honk.audio}</CardContent>
        )}
        {honk.audio && !honk.audio.endsWith('processing...') && (
          <CardMedia
            component="audio"
            src={honk.audio}
            ref={mediaRef}
            title={honk.body}
            controls
            playsInline
          />
        )}
        <CardContent>{honk.body}</CardContent>
        <CardActions sx={{ mx: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Slider
              aria-label="Rate"
              defaultValue={honk.rating || 5}
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => <RateIcon rating={value} />}
              sx={{ width: 250 }}
              onChangeCommitted={(e, rating) => onRate(rating as number)}
            />
          </Box>
          <Box>
            <Badge
              badgeContent={honk.commentCount}
              max={99}
              color="success"
              overlap="circular"
            >
              <IconButton
                aria-label="comments"
                onClick={() => setShowCommentForm(!showCommentForm)}
              >
                <CommentIcon />
              </IconButton>
            </Badge>

            {honk.audits.length > 0 && (
              <IconButton aria-label="history">
                <HistoryIcon />
              </IconButton>
            )}
            {currentUser?.handle === honk.user.handle && (
              <>
                <IconButton aria-label="edit">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={onDelete} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </Box>
        </CardActions>

        {showCommentForm && (
          <CardContent>
            <CommentList honkUuid={honk.uuid} />
            <CommentForm honkUuid={honk.uuid} />
            {/* TODO: When this badboy gets popped open, need to load comments for the honk (paginated)*/}
          </CardContent>
        )}
      </Card>
    </Box>
  );
}
