import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import useCurrentUser from '../../services/useUser';

const Login = () => {
  const { actions, error, loading } = useCurrentUser();

  return (
    <Card
      sx={{
        justifySelf: 'center',
        m: '0 auto',
        maxWidth: 480,
        width: '100%',
      }}
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = Object.fromEntries(
          new FormData(e.currentTarget),
        );
        actions.login(formData as any);
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {error && <Alert severity="error">{error.message}</Alert>}
        <TextField
          label="Email"
          name="email"
          type="email"
          required
          disabled={loading}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          required
          disabled={loading}
        />
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={loading}
        >
          Login
        </LoadingButton>
        <Button component={Link} to="/sign-up" disabled={loading}>
          Don't have an account? Sign Up
        </Button>
      </CardActions>
    </Card>
  );
};

export default Login;
