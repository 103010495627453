import { AddAPhoto, VideoCameraFront, Mic } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, TextField } from '@mui/material';
import { useEffect } from 'react';
import Honk from '../../components/Honk';
import useHonks from '../../services/useHonks';

const Flock = () => {
  const { actions, honks, loading } = useHonks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => actions.getHonks(), []);

  return (
    <>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          const form = e.target as HTMLFormElement;
          actions.createHonk(new FormData(form)).then(() => form.reset());
        }}
      >
        <Box display="flex">
          <TextField
            fullWidth
            name="honk[body]"
            label="Send a honk!"
            placeholder="What would you like to say?"
            multiline
          />
          <LoadingButton type="submit" loading={loading}>
            Send
          </LoadingButton>
        </Box>
        <Box display="flex" gap={2} sx={{ my: 1 }}>
          <input
            style={{ display: 'none' }}
            accept="image/jpeg, image/jpg, image/png, image/gif, image/heic, image/heif, image/webp"
            id="honk_image"
            name="honk[image]"
            type="file"
          />
          <label htmlFor="honk_image">
            <IconButton color="primary" component="span">
              <AddAPhoto />
            </IconButton>
          </label>
          <input
            style={{ display: 'none' }}
            accept="video/mp4, application/x-mpeg, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv, video/webm"
            id="honk_video"
            name="honk[video]"
            type="file"
          />
          <label htmlFor="honk_video">
            <IconButton color="primary" component="span">
              <VideoCameraFront />
            </IconButton>
          </label>
          <input
            style={{ display: 'none' }}
            accept="audio/aac, audio/basic, audio/mid, audio/mpeg, audio/mp4, audio/x-aiff, audio/ogg, audio/vnd.wav"
            id="honk_audio"
            name="honk[audio]"
            type="file"
          />
          <label htmlFor="honk_audio">
            <IconButton color="primary" component="span">
              <Mic />
            </IconButton>
          </label>
        </Box>
      </Box>
      <Box>
        {honks.map((honk) => (
          <Honk
            onDelete={() => actions.deleteHonk(honk.uuid)}
            key={honk.uuid}
            honk={honk}
            onRate={(rating) => actions.rateHonk(honk.uuid, rating)}
          />
        ))}
      </Box>
    </>
  );
};

export default Flock;
