export default interface User {
  id: number;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  email: string;
  handle: string;
  followingCount: string;
  followerCount: string;
  pronouns?: string;
  phone: string;
  updatedAt: string;
  createdAt: string;
  notificationsCount: string;
  currentUserFollowing: string;
}

export const isUser = (obj: any): obj is User => Boolean(obj.handle);
