import { Avatar, Box } from '@mui/material';
import Honk, { isHonk } from '../../interfaces/Honk';
import User, { isUser } from '../../interfaces/User';

interface EggProps {
  option: User | Honk;
}

const Egg = ({ option, ...props }: EggProps) => {
  return (
    <Box component="li" display="flex" gap={2} {...props}>
      {isUser(option) && (
        <>
          <Avatar src={option.avatar} /> {option.handle}
        </>
      )}
      {isHonk(option) && (
        <>
          <Avatar src="/logo.png" /> {option.body}
        </>
      )}
    </Box>
  );
};

export default Egg;
