import { signal } from '@preact/signals-core';
import { AxiosError } from 'axios';
import Comment from '../interfaces/Comment';
import { useSignal } from '../utils/react-signals-lite';
import api from './api';

const commentData = signal<{
  comments: Comment[];
  loading: boolean;
  error: boolean | { status?: number; message?: string };
}>({ comments: [], loading: false, error: false });

const useComments = () => {
  useSignal(commentData);
  const actions = {
    createComment(newComment: any) {
      commentData.value = { ...commentData.value, loading: true };
      return api
        .post(`/honks/${newComment.honkUuid}/comment`, { comment: newComment })
        .then(({ data: comment }) => {
          commentData.value = {
            ...commentData.value,
            comments: [...commentData.value.comments, comment],
            loading: false,
            error: false,
          };
        })
        .catch((err: AxiosError) => {
          commentData.value = {
            ...commentData.value,
            loading: false,
            error: { status: err.status, message: err.message },
          };
        });
    },
    getComments(honkUuid?: string) {
      commentData.value = { ...commentData.value, loading: true };
      const endpoint = `honks/${honkUuid}/comments`;
      api
        .get(endpoint)
        .then((res) => {
          commentData.value = {
            comments: res.data.reverse(),
            loading: false,
            error: false,
          };
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const status = err.response?.status;
          const message = err.response?.data.message;
          commentData.value = {
            ...commentData.value,
            loading: false,
            error: { status, message },
          };
        });
    },
  };
  return { actions, ...commentData.value };
};

export default useComments;
