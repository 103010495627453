import Box from '@mui/material/Box/Box';
import { useEffect } from 'react';
import useComments from '../../services/useComments';
import Comment from './index';

type Props = {
  honkUuid: string;
};

export default function CommentList({ honkUuid }: Props) {
  const { actions, comments, loading, error } = useComments();
  useEffect(() => {
    if (honkUuid) {
      actions.getComments(honkUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [honkUuid]);
  return (
    <Box>
      {loading && 'loading...'}
      {error && 'ERROR!'}
      {comments.map((comment) => (
        <Comment key={comment?.uuid} comment={comment} />
      ))}
    </Box>
  );
}
