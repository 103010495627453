import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Box from '@mui/material/Box/Box';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField/TextField';
import useComments from '../../services/useComments';

type Props = {
  honkUuid: string;
};

export default function CommentForm({ honkUuid }: Props) {
  const { actions, loading, error } = useComments();
  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const comment = Object.fromEntries(new FormData(form));
        actions.createComment(comment).then(() => form.reset());
      }}
    >
      <Box display="flex">
        {error && 'ERROR'}
        <TextField
          fullWidth
          name="body"
          id="comment_body"
          label="Leave a comment"
          placeholder="Leave a comment"
          multiline
        />
        <Input name="honkUuid" value={honkUuid} type="hidden" />
        <LoadingButton loading={loading} type="submit">
          Comment
        </LoadingButton>
      </Box>
    </Box>
  );
}
