import { Autocomplete, Box, TextField } from '@mui/material';
import { AxiosRequestConfig, isCancel } from 'axios';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Honk, { isHonk } from '../../interfaces/Honk';
import User, { isUser } from '../../interfaces/User';
import api from '../../services/api';
import useCurrentUser from '../../services/useUser';
import Egg from './Egg';

const findEggs = ({
  signal,
  search,
  params,
}: {
  signal: AbortSignal;
  search: string;
  params: AxiosRequestConfig['params'];
}) =>
  api.get<{ users: User[]; honks: Honk[] }>(`/search/${search}`, {
    params,
    signal,
  });

const useEggHunter = (search: string) => {
  const [data, setData] = useState<(Honk | User)[] | []>([]);
  const [error, setError] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!search) return;
    const abortController = new AbortController();
    setLoading(true);
    findEggs({
      signal: abortController.signal,
      search,
      params: { page: 1 },
    })
      .then(({ data }) => {
        setData([...data.users, ...data.honks]);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!isCancel(err)) setError(true);
        setLoading(false);
      });
    return () => abortController.abort();
  }, [search]);

  return { data, error, loading };
};

const EggHunter = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const onSearch = useMemo(() => debounce(setSearch, 200), []);

  const { data, error, loading } = useEggHunter(search);

  const onOptionSelect = (_: any, option: User | Honk) => {
    if (!option) return;
    if (isUser(option)) {
      if (option.handle === currentUser?.handle) navigate('/profile');
      else navigate(`/goose/${option.handle}`);
    }
    if (isHonk(option)) navigate(`/honks/${option.uuid}`);
    setOpen(false);
  };

  return (
    <Box flex={1}>
      <Autocomplete<User | Honk, false, true>
        sx={{ maxWidth: '500px', mx: 'auto' }}
        clearOnBlur
        options={data}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option) => {
          if (isUser(option)) return option.handle;
          if (isHonk(option)) return option.body;
          return '';
        }}
        renderOption={(props, option) => <Egg {...props} option={option} />}
        onChange={onOptionSelect}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            error={Boolean(error)}
            placeholder="Search"
            onChange={(e) => onSearch(e.target.value)}
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default EggHunter;
