import { signal } from '@preact/signals-core';
import { AxiosError } from 'axios';
import Honk from '../interfaces/Honk';
import { useSignal } from '../utils/react-signals-lite';
import api from './api';

const honkData = signal<{
  honk?: Honk;
  loading: boolean;
  error: boolean | { status?: number; message?: string };
}>({ loading: false, error: false });

const useHonk = () => {
  useSignal(honkData);
  const actions = {
    getHonk(id: string) {
      honkData.value = { ...honkData.value, loading: true };
      api
        .get(`/honks/${id}`)
        .then((res) => {
          honkData.value = {
            honk: res.data,
            loading: false,
            error: false,
          };
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const status = err.response?.status;
          const message = err.response?.data.message;
          honkData.value = {
            ...honkData.value,
            loading: false,
            error: { status, message },
          };
        });
    },
    rateHonk(id: string | undefined, rating: number) {
      api.post<Honk>(`/honks/${id}/vote`, { rating }).then(({ data }) => {
        honkData.value = { ...honkData.value, honk: data };
      });
    },
  };
  return { actions, ...honkData.value };
};

export default useHonk;
