import Avatar from '@mui/material/Avatar';
import { Card, CardHeader, Link } from '@mui/material';
import User from '../../interfaces/User';

type Props = {
  goose: User;
};
export default function Friend({ goose }: Props) {
  if (!goose) return null;

  const name: string = goose.firstName + ' ' + goose.lastName;
  return (
    <Card
      sx={{ my: 2 }}
      component={Link}
      href={`/goose/${goose.handle}`}
      underline="none"
    >
      <CardHeader
        title={`@${goose.handle} ${goose.pronouns}`}
        subheader={name}
        avatar={<Avatar src={goose.avatar} />}
      />
    </Card>
  );
}
