import { signal } from '@preact/signals-core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Friend from '../../components/DisplayGoose/Friend';
import api from '../../services/api';
import { useSignal } from '../../utils/react-signals-lite';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { TabContext } from '@mui/lab';
import User from '../../interfaces/User';
import { ChevronLeft } from '@mui/icons-material';

const friendsData = signal<{ data: any; error: any; loading: boolean }>({
  data: null,
  error: null,
  loading: false,
});

const useFriends = (handle?: string) => {
  useSignal(friendsData);
  useEffect(() => {
    if (!handle) return;
    friendsData.value = { ...friendsData.value, loading: true };
    api
      .get(`/follows?goose=${handle}`)
      .then(
        ({ data }) =>
          (friendsData.value = {
            ...friendsData.value,
            data: data,
            error: null,
            loading: false,
          }),
      )
      .catch(
        (err) =>
          (friendsData.value = {
            ...friendsData.value,
            error: err.response.data,
            loading: false,
          }),
      );
  }, [handle]);

  return { ...friendsData.value };
};

const Friends = () => {
  const navigate = useNavigate();
  const { handle } = useParams<{ handle: string }>();
  const [value, setValue] = useState('Following');
  const friends = useFriends(handle);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  if (!friends) return null;
  return (
    <>
      <h3>
        <IconButton
          aria-label="bck"
          onClick={() => navigate(-1)}
          sx={{ mr: 1 }}
        >
          <ChevronLeft />
        </IconButton>
        {handle}'s Friends
      </h3>
      {friends.data && (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              aria-label="Following and Followers"
              onChange={handleChange}
            >
              <Tab label="Following" value={'Following'} />
              <Tab label="Followers" value={'Followers'} />
            </Tabs>
          </Box>
          <TabPanel value={'Following'}>
            <Box display="flex" flexDirection="column" gap="2">
              {JSON.parse(friends.data.following).map((goose: User) => (
                <Friend goose={goose} key={'following_' + goose.handle} />
              ))}
            </Box>
          </TabPanel>
          <TabPanel value={'Followers'}>
            <Box display="flex" flexDirection="column" gap="2">
              {JSON.parse(friends.data.followers).map((goose: User) => (
                <Friend goose={goose} key={'follower_' + goose.handle} />
              ))}
            </Box>
          </TabPanel>
        </TabContext>
      )}
    </>
  );
};

export default Friends;
