import axios from 'axios';
import transform from 'lodash/transform';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isObject from 'lodash/isObject';

const createTransform = (transformer: (str?: string) => string) =>
  function transformize(obj: Record<string, unknown>) {
    return transform(
      obj,
      (
        result: Record<string, unknown>,
        value: unknown,
        key: string,
        target,
      ) => {
        const camelKey = isArray(target) ? key : transformer(key);
        result[camelKey] = isObject(value)
          ? transformize(value as Record<string, unknown>)
          : value;
      },
    );
  };
const camelize = createTransform(camelCase);
const snakize = createTransform(snakeCase);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => ({
    ...config,
    data: config.data instanceof FormData ? config.data : snakize(config.data),
  }),
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (config) => ({
    ...config,
    data: camelize(config.data),
  }),
  (error) => Promise.reject(error),
);

export default api;
